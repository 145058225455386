<template>
  <div>
    <b-row>
      <b-col
        sm="6"
        class="d-flex flex-column justify-content-center"
      >
        <b-card-text class="font-weight-bolder mb-0">
          {{ $t('roles') }}
        </b-card-text>
      </b-col>
      <b-col sm="6">
        <div class="demo-inline-spacing float-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.permission-settings-modal
            variant="primary"
            class="btn-icon m-0 mb-1 ml-1"
            @click="setAbilities"
          >
            {{ $t('Add') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-table
      id="rules-table"
      responsive
      striped
      :items="[{ roleName: 'owner', ability: ownerAbilities }, ...getCurrentStore.roles].filter(a => a.roleName)"
      :fields="rolesTableInfo"
      table-class="custom-table"
    >
      <template #cell(roleName)="data">
        <div v-if="data.value === 'owner'">
          {{ $t('owner') }}
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>

      <template #cell(action)="data">
        <div class="d-flex justify-content-center">
          <b-dropdown
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            toggle-class="px-0 py-50 bg-transparent"
            variant="flat-dark"
            no-caret
            right
          >
            <template
              v-if="data.item.roleName !== 'owner'"
              v-slot:button-content
            >
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="cursor-pointer"
              />
            </template>
            <b-dropdown-item
              v-b-modal.permission-settings-modal
              @click="setAbilities(data.item)"
            >
              {{ $t('edit') }}
            </b-dropdown-item>
            <b-dropdown-item
              @click="deleteRole"
            >
              {{ $t('delete') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

      <template #cell()="data">
        <div class="text-center">
          {{ data.value }}
        </div>
      </template>

      <template #head()="data">
        <div style="min-width: 30px;">
          {{ $t(data.label) }}
        </div>
      </template>
    </b-table>

    <validation-observer
      ref="settings-general"
      v-slot="{ invalid }"
    >
      <b-modal
        id="permission-settings-modal"
        :title="$t('roles and permissions')"
        :hide-header-close="true"
        :no-close-on-esc="true"
        :ok-title="$t('Add')"
        :cancel-title="$t('Cancel')"
        :ok-disabled="invalid"
        cancel-variant="outline-primary"
        footer-class="justify-content-end flex-row-reverse"
        centered
        size="lg"
        @ok="rolesStoreAction"
        @cancel="setAbilities"
      >
        <validation-provider
          #default="{ errors }"
          :name="$t('role name')"
          rules="required"
        >
          <b-form-group
            :label="$t('role name')"
            label-for="role-name"
          >
            <b-form-input
              id="role-name"
              v-model="roleName"
              :placeholder="$t('role name')"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <b-card-text class="mb-0">
          {{ $t('roles') }}
        </b-card-text>
        <b-table
          :items="abilities"
          :fields="fields"
          striped
          responsive
        >
          <template #cell(action)="data">
            <b-form-group>
              <b-form-checkbox-group
                id="actions"
                v-model="data.item.action"
                name="actions"
                class="demo-inline-spacing"
              >
                <b-form-checkbox value="read">
                  {{ $t('read') }}
                </b-form-checkbox>
                <b-form-checkbox value="create">
                  {{ $t('create') }}
                </b-form-checkbox>
                <b-form-checkbox value="update">
                  {{ $t('update') }}
                </b-form-checkbox>
                <b-form-checkbox
                  :disabled="data.item.subject === 'store'"
                  value="delete"
                >
                  {{ $t('delete') }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </template>

          <template #cell(subject)="data">
            <div>
              {{ $t(data.value) }}
            </div>
          </template>
        </b-table>
      </b-modal>
    </validation-observer>
  </div>
</template>
<script>
import {
  BButton, BCardText, BTable, BDropdown, BDropdownItem, BModal, BFormGroup, BFormInput, BFormCheckbox, BFormCheckboxGroup, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate'
import { required } from '@validations'
import * as rules from 'vee-validate/dist/rules'
import { restrictAbility, manageAbility } from '@/libs/acl/config'
import { mapGetters } from 'vuex'

localize('tw')
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

export default {
  components: {
    BRow,
    BCol,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BModal,
    BButton,
    BCardText,
    BTable,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      roleAction: 'add',
      required,
      roleName: null,
      rolesTableInfo: [
        { key: 'roleName', label: 'role name', tdClass: 'text-center' },
        { key: 'action', label: 'Action' },
      ],
      fields: [
        {
          key: 'subject', label: this.$t('pages'),
        },
        {
          key: 'action', label: this.$t('permissions'),
        },
      ],
      abilities: [],
      ownerAbilities: [],
    }
  },
  computed: {
    ...mapGetters(['getCurrentStore']),
  },
  mounted() {
    this.setAbilities()
  },
  methods: {
    rolesStoreAction() {
      store.dispatch('storeRolesAction', { role: { roleName: this.roleName, ability: this.abilities }, action: this.roleAction })
    },
    deleteRole() {
      store.dispatch('storeRolesAction', { role: { roleName: this.roleName, ability: this.abilities }, action: 'delete' })
    },
    setAbilities(params) {
      const { paymentPackage, roleName, ability } = params || {}
      this.roleName = null
      this.roleAction = 'add'

      if (paymentPackage) {
        this.abilities = restrictAbility(paymentPackage)
      } else if (roleName) {
        this.roleName = roleName
        this.abilities = ability
        this.roleAction = 'update'
      } else {
        this.abilities = restrictAbility(this.getCurrentStore.billing.paymentPackage)
        this.ownerAbilities = manageAbility(this.getCurrentStore.billing.paymentPackage)
      }
    },
  },
}
</script>
