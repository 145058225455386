<template>
  <div class="customizer-section">
    <b-form-group :label="$t('background')">
      <div
        v-if="skin !== 'custom-a'"
        class="p-1 d-inline-block rounded mr-1 cursor-pointer"
        :class="['bg-dark', {'border border-light': skin !== 'dark'}, {'mark-active': skin === 'dark'}]"
        @click="skin='dark'"
      />
      <div
        class="p-1 d-inline-block rounded mr-1 cursor-pointer"
        :class="['bg-light', {'border border-dark': !['light'].includes(skin)}, {'mark-active': ['custom-a', 'light'].includes(skin)}]"
        @click="skin='light'"
      />
    </b-form-group>

    <!-- Navbar Color -->
    <b-form-group
      :label="$t('theme colors')"
    >
      <div
        class="p-1 d-inline-block rounded mr-1 cursor-pointer"
        :class="['bg-primary', {'border border-light': !['dark', 'light'].includes(skin)}, {'mark-active': ['dark', 'light'].includes(skin)}]"
        @click="skin = (skin === 'custom-a') ? 'dark' : skin"
      />
      <div
        class="p-1 d-inline-block rounded mr-1 cursor-pointer"
        :class="['bg-custom-a', {'border border-light': skin !== 'custom-a'}, {'mark-active': skin === 'custom-a'}]"
        @click="skin = 'custom-a'"
      />
    </b-form-group>

    <!-- site language -->
    <b-form-group
      :label="$t('language')"
    >
      <b-form-select
        v-model="language"
        :options="languages"
      />
    </b-form-group>
    <div class="demo-inline-spacing">
      <b-button
        variant="primary"
        :disabled="btnDisabled"
        @click="updateUserInfo"
      >
        {{ $t('Save') }}
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BButton, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { mapGetters } from 'vuex'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      skin: 'dark',
      language: 'tw',
      languages: [{ value: 'tw', text: this.$t('traditional chinese') }, { value: 'en', text: this.$t('english') }],
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    btnDisabled() {
      let status = true
      if ((this.user.skin && this.user.skin !== this.skin) || !this.user.skin) {
        status = false
      }
      if ((this.user.language && this.user.language !== this.language) || !this.user.language) {
        status = false
      }
      return status
    },
  },
  mounted() {
    this.skin = this.user.skin || 'dark'
    this.language = this.user.language || 'tw'
  },
  methods: {
    updateUserInfo() {
      this.$i18n.locale = this.language
      store.dispatch('updateUserInfo', { skin: this.skin, language: this.language })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.customizer-section {
  .dark-layout {
    border-color: $theme-dark-border-color;
  }

  .bg-custom-a {
    background-color: #252525,
  }

  .bg-dark {
    background-color: #161D31 !important;
  }

  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;;
    &:last-of-type {
    margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

</style>
