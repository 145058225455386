<template>
  <div>
    <div class="d-flex align-items-center mb-1 justify-content-between">
      <b-card-text class="font-weight-bolder mb-0">
        {{ $t('Groups') }}
      </b-card-text>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-modal.customer-group-modal
        variant="primary"
        class="btn-icon m-0"
      >
        {{ $t('add') }}
      </b-button>
    </div>
    <div class="overflow-hidden rounded">
      <b-table
        id="groups-table"
        responsive
        striped
        :items="groups"
        :fields="groupsTableInfo"
        table-class="custom-table small-row-table"
        thead-class="text-center"
      >
        <template #cell(groupName)="data">
          <div v-if="data.item.groupName === 'no groups'">
            {{ $t(data.item.groupName) }}
          </div>
          <div v-else>
            {{ data.item.groupName }}
          </div>
        </template>

        <template #cell(action)="data">
          <div class="d-flex justify-content-center">
            <b-dropdown
              v-if="data.item.groupName !== 'undefined'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              toggle-class="px-0 py-50 bg-transparent"
              variant="flat-dark"
              no-caret
              right
            >
              <template
                v-slot:button-content
              >
                <feather-icon
                  :id="`box-pop-menu-${data.item.id}`"
                  icon="MoreVerticalIcon"
                  size="18"
                  class="cursor-pointer"
                />
              </template>
              <!-- <b-dropdown-item>
                {{ $t('edit') }}
              </b-dropdown-item> -->
              <b-dropdown-item
                @click="groupHandle('delete', data.item.groupName)"
              >
                {{ $t('delete') }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
        <template #cell()="data">
          <div class="text-center">
            {{ data.value }}
          </div>
        </template>
        <template #head()="data">
          <div style="min-width: 30px;">
            {{ $t(data.label) }}
          </div>
        </template>
      </b-table>
    </div>
    <b-modal
      id="customer-group-modal"
      :title="$t('add group')"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :ok-title="$t('Add')"
      :cancel-title="$t('Cancel')"
      :ok-disabled="!form.name || !form.desc"
      cancel-variant="outline-primary"
      footer-class="justify-content-end flex-row-reverse"
      centered
      @ok="groupHandle('add')"
    >
      <b-form-group
        :label="$t('group name')"
        label-for="group-name"
      >
        <b-form-input
          id="group-name"
          v-model="form.name"
          :placeholder="$t('group name')"
        />
      </b-form-group>
      <b-form-group
        :label="$t('description')"
        label-for="group-description"
      >
        <b-form-textarea
          id="group-description"
          v-model="form.desc"
          :placeholder="$t('description')"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BCardText, BTable, BDropdown, BDropdownItem, BModal, VBModal, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { mapGetters } from 'vuex'

export default {
  components: {
    BButton,
    BCardText,
    BTable,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        desc: null,
        name: null,
      },
      groupsTableInfo: [
        { key: 'groupName', label: 'group name' },
        { key: 'groupDesc', label: 'description' },
        { key: 'action', label: 'action' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      store: 'getCurrentStore',
      groups: 'getGroups',
    }),
  },
  methods: {
    /* userSelectHandle(uid) {
      // console.log(uid)
    }, */
    groupHandle(handleKey, name) {
      if (handleKey === 'add') {
        if (this.form.name && this.form.desc && this.form.name !== '') {
          store.dispatch('createStoreGroup', { uid: this.user.uid, sid: this.user.sid, ...this.form })
        }
      } else if (handleKey === 'delete') {
        store.dispatch('deleteStoreGroup', {
          uid: this.user.uid, sid: this.user.sid, name, groups: this.groups,
        })
      }
    },
  },
}
</script>
