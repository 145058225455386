<template>
  <b-row>
    <b-col sm="3">
      <!--
        Botton group
        Return a key emitted from a button clicked
      -->
      <loan-button-group
        :buttons="groupButtons"
        :active-key="activeInfoBtnGroup"
        variant="primary"
        button-width="100%"
        text-align="text-left"
        @activeKey="setActiveInfoBtnGroup"
      />
    </b-col>
    <b-col sm="9">
      <component
        :is="groupButtons.find(d => d.key === activeInfoBtnGroup).component"
        :user-info="user"
      />
    </b-col>
  </b-row>
</template>
<script>
import store from '@/store'
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import LoanButtonGroup from './components/elements/LoanButtonGroup.vue'
import General from './components/settings/General.vue'
import Password from './components/settings/Password.vue'
import Management from './components/settings/Management.vue'
import Security from './components/settings/Security.vue'
import System from './components/settings/System.vue'
import Download from './components/settings/Download.vue'

export default {
  components: {
    BRow,
    BCol,
    LoanButtonGroup,
    General,
    Password,
    Management,
    Security,
    System,
    Download,
  },
  data() {
    return {
      /**
        Array of the buttons to category the customer information
        - transaction, basic, family, guarantor, credit, debt -
        This button group has the radio feature
      */
      groupButtons: [
        {
          key: 'general',
          name: 'General',
          icon: 'AlertCircleIcon',
          component: General,
          requireStore: false,
        },
        {
          key: 'password',
          name: 'Password',
          icon: 'LockIcon',
          component: Password,
          requireStore: false,
        },
        {
          key: 'management',
          name: 'Management',
          icon: 'UserCheckIcon',
          component: Management,
          requireStore: true,
        },
        {
          key: 'system',
          name: 'System',
          icon: 'CommandIcon',
          component: System,
        },
        {
          key: 'security',
          name: 'Security',
          icon: 'ShieldIcon',
          component: Security,
        },
      ],
      activeInfoBtnGroup: 'general',
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  mounted() {
    if (!this.user.sid || !this.$can('read', 'store')) {
      this.groupButtons.splice(this.groupButtons.map(item => item.component).indexOf(Management), 1)
      this.groupButtons.splice(this.groupButtons.map(item => item.component).indexOf(Security), 1)
    }
    /** Set to show in Store for a Back button  */
    store.commit('app/UPDATE_NAV_BACK_BUTTON', { buttonShow: true, backName: 'dashboard' })
  },
  methods: {
    setActiveInfoBtnGroup(key) {
      this.$set(this, 'activeInfoBtnGroup', key)
    },
  },
  beforeRouteLeave(to, from, next) {
    /** Set to hide in Store for a Back button  */
    store.commit('app/UPDATE_NAV_BACK_BUTTON', { buttonShow: false, backName: 'dashboard' })
    next()
  },
}
</script>
