<template>
  <div>
    <!-- <div>
      <b-card-text class="font-weight-bold">
        {{ $t('Company Logo') }}
      </b-card-text>
      <b-avatar
        :src="imgFile"
        rounded
        size="5.5rem"
        class="mr-2 mb-1"
      />
      <p>JPG, GIF or PNG are allowed. The maximum photo size is 800kB</p>
    </div> -->
    <b-row>
      <b-col
        v-if="form.billing"
        cols="7"
        class="mb-1"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50 text-nowrap">
              <feather-icon
                icon="PackageIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('system plans') }}</span>
            </th>
            <td class="pb-50">
              <b-badge
                v-for="pkg in form.billing.paymentPackage"
                :key="pkg"
                variant="primary"
                class="mr-1"
              >
                {{ $t(pkg) }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="RotateCwIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('payment plan') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ form.billing.paymentDuration }} {{ $t('months') }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CreditCardIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('payment method') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ $t(form.billing.paymentMethod) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('next payment date') }}</span>
            </th>
            <td class="pb-50">
              {{ form.billing.paymentDate | moment('YYYY/MM/DD') }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('sales') }}</span>
            </th>
            <td>
              {{ form.billing.sales }}
            </td>
          </tr>
        </table>
      </b-col>
      <b-col
        cols="12"
        class="mb-1"
      >
        <hr class="invoice-spacing">
      </b-col>
      <b-col sm="7">
        <b-form-group
          :label="$t('store name')"
          label-for="storeName"
          class="font-weight-bold"
        >
          <b-form-input
            id="storeName"
            v-model="form.storeName"
            :disabled="true"
            :placeholder="$t('store name')"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      :disabled="true"
      variant="primary"
    >
      {{ $t('Save') }}
    </b-button>
  </div>
</template>
<script>
import {
  BButton, BFormInput, BFormGroup, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import store from '@/store'
import { mapGetters } from 'vuex'

export default {
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {},
    }
  },
  computed: {
    ...mapGetters({
      currentStore: 'getCurrentStore',
    }),
  },
  mounted() {
    this.setDefault()
  },
  methods: {
    setDefault() {
      this.form = {
        storeName: this.currentStore.storeName,
        billing: this.currentStore.billing,
      }
    },
  },
}
</script>
