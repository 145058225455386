<template>
  <b-card>
    <h5>{{ $t('delete all data') }}</h5>
    <b-card-text class="font-weight-bold">
      {{ $t('delete data info') }}
    </b-card-text>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      @click="confirmDelete"
    >
      <feather-icon
        icon="DownloadIcon"
        class="mr-50"
      />
      <span class="align-middle">{{ $t('delete') }}</span>
    </b-button>
  </b-card>
</template>
<script>
import {
  BCard, BButton, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BCardText,
  },
  directives: {
    Ripple,
  },
  methods: {
    confirmDelete() {
      this.$swal({
        title: this.$t('are you sure you want to delete?'),
        html: `${this.$t('you may not be able to recover after deletion!')}<br><div class="text-left mt-1">${this.$t('please type in')} "<span class="text-danger">${this.$t('delete')}</span>"</div>`,
        input: 'text',
        icon: 'warning',
        confirmButtonText: this.$t('yes, delete!'),
        cancelButtonText: this.$t('cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        preConfirm: value => {
          if (!value) {
            this.$swal.showValidationMessage(
              `<i class="fa fa-info-circle">${this.$t('text input is required')}</i>`,
            )
          } else if (value !== this.$t('delete')) {
            this.$swal.showValidationMessage(
              `<i class="fa fa-info-circle">${this.$t('please correct the text')}</i>`,
            )
          }
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('deleteAllStoreData')
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('store data is successfully deleted'),
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'fa-info-circle',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>
