<template>
  <b-card>
    <validation-observer
      ref="settings-general"
      v-slot="{ invalid }"
    >
      <b-row>
        <b-col sm="6">
          <validation-provider
            #default="{ errors }"
            :name="$t('Old password')"
            rules="required"
          >
            <b-form-group
              :label="$t('Old password')"
              label-for="oldPassword"
            >
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  id="oldPassword"
                  v-model="oldPassword"
                  :type="oldPasswordFieldType"
                  :placeholder="$t('Old password')"
                  :disabled="!editable"
                  :state="errors.length > 0 ? false : null"
                  required
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="oldPasswordToggleIcon"
                    @click="toggleOldPasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <validation-provider
            #default="{ errors }"
            :name="$t('New password')"
            rules="required"
            vid="newPassword"
          >
            <b-form-group
              :label="$t('New password')"
              label-for="newPassword"
            >
              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  id="newPassword"
                  ref="newPassword"
                  v-model="newPassword"
                  :type="passwordFieldType"
                  :placeholder="$t('New password')"
                  :disabled="!editable"
                  :state="errors.length > 0 ? false : null"
                  required
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="newPasswordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col sm="6">
          <validation-provider
            #default="{ errors }"
            :name="$t('Enter the password again')"
            rules="required|confirmed:newPassword"
          >
            <b-form-group
              :label="$t('Enter the password again')"
              label-for="confirmPassword"
            >
              <b-form-input
                id="confirmPassword"
                v-model="confirmPassword"
                type="password"
                :placeholder="$t('Enter the password again')"
                :disabled="!editable"
                :state="errors.length > 0 ? false : null"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <div>
        <b-button
          variant="primary"
          class="mr-1"
          :disabled="invalid && editable"
          @click="actionHandle"
        >
          {{ editable ? $t('Save') : $t('Edit') }}
        </b-button>
        <b-button
          variant="outline-primary"
          @click="actionCancelHandle"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </validation-observer></b-card>
</template>
<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { auth } from '@/firebase'
import store from '@/store'
import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate'

import { required, confirmed } from '@validations'
import * as rules from 'vee-validate/dist/rules'

localize('tw')
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    userInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      confirmed,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      oldPasswordFieldType: 'password',
      validations: {
        oldPassword: false,
        newPassword: false,
        confirmPassword: false,
      },
      editable: false,
    }
  },
  computed: {
    validate() {
      const matchPassword = this.newPassword === this.confirmPassword
      return !(Object.values(this.validations).indexOf(false) > -1) && matchPassword
    },
    oldPasswordToggleIcon() {
      return this.oldPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    newPasswordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    defaultValue() {
      this.oldPassword = ''
      this.newPassword = ''
      this.confirmPassword = ''
    },
    toggleOldPasswordVisibility() {
      this.oldPasswordFieldType = this.oldPasswordFieldType === 'password' ? 'text' : 'password'
    },
    actionHandle() {
      if (!this.editable) {
        this.$set(this, 'editable', true)
      } else {
        this.$set(this, 'editable', false)
        if (this.oldPassword !== '' && this.newPassword !== '' && this.newPassword === this.confirmPassword) {
          store.dispatch('updatePassword', { oldPassword: this.oldPassword, newPassword: this.newPassword }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('updated successfully'),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t(error.message),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        }
      }
    },
    actionCancelHandle() {
      this.$set(this, 'editable', false)
    },
    resetPassword(email) {
      auth.doPasswordReset(email)
    },
  },
}
</script>
