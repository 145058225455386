<template>
  <b-card>
    <div>
      <loan-button-group
        :buttons="groupButtons"
        :active-key="activeInfoBtnGroup"
        variant="primary"
        @activeKey="setActiveInfoBtnGroup"
      />
    </div>
    <component
      :is="groupButtons.find(d => d.key === activeInfoBtnGroup).component"
    />
  </b-card>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import LoanButtonGroup from '@/views/components/elements/LoanButtonGroup.vue'
import DeleteData from '@/views/components/settings/security/DeleteData.vue'
import MemberLogs from '@/views/components/settings/security/MemberLogs.vue'

export default {
  components: {
    LoanButtonGroup,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      groupButtons: [
        {
          key: 'memberLogs',
          name: 'Member Logs',
          component: MemberLogs,
        },
        /* {
          key: 'rolesPermissions',
          name: 'Roles and Permissions',
          component: RolesPermissions,
        },
        {
          key: 'customerGroups',
          name: 'Customer Groups',
          component: CustomerGroups,
        }, */
      ],
      activeInfoBtnGroup: 'memberLogs',
    }
  },
  mounted() {
    if (this.$can('delete', 'store')) {
      this.groupButtons.push({
        key: 'deleteData',
        name: 'Delete Data',
        component: DeleteData,
      })
    }
  },
  methods: {
    setActiveInfoBtnGroup(key) {
      this.$set(this, 'activeInfoBtnGroup', key)
    },
  },
}
</script>
