<template>
  <validation-observer
    ref="settings-general"
    v-slot="{ invalid, untouched }"
  >
    <b-card>
      <b-row>
        <b-col cols="2">
          <div class="mb-2">
            <label for="fileInput">
              <input
                id="fileInput"
                type="file"
                hidden
                accept="image/*"
                @change="fileChange"
              >
              <b-avatar
                :src="imgFile ? imgFile : userInfo.photoURL"
                size="5.5rem"
              />
            </label>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <validation-provider
            #default="{ errors }"
            :name="$t('name')"
            rules="required"
          >
            <b-form-group
              :label="$t('Name')"
              label-for="userName"
            >
              <b-form-input
                id="userName"
                v-model="userName"
                :placeholder="$t('Name')"
                :disabled="!editable"
                :state="errors.length > 0 ? false : validations.userName !== null ? validations.userName : null"
                :value="userName"
                @change="value => changeValue('userName', value)"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <div>
        <b-button
          variant="primary"
          class="mr-1"
          :disabled="editable && (editable && (invalid || untouched || Object.values(validations).some(val => val !== true)))"
          @click="actionHandle"
        >
          {{ editable ? $t('Save') : $t('Edit') }}
        </b-button>
        <b-button
          variant="outline-primary"
          @click="actionCancelHandle"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-card>
  </validation-observer>
</template>
<script>
import {
  BCard, BAvatar, BRow, BCol, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate'

import { required } from '@validations'
import * as rules from 'vee-validate/dist/rules'

localize('tw')
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

export default {
  components: {
    BCard,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      imgFile: null,
      userName: '',
      userEmail: '',
      validations: {
        userName: null,
      },
      editable: false,
    }
  },
  computed: {
    validate() {
      return !(Object.values(this.validations).indexOf(false) > -1)
    },
  },
  mounted() {
    this.defaultValue()
  },
  methods: {
    defaultValue() {
      this.userName = this.userInfo.displayName
    },
    fileChange(event) {
      const file = event.target.files[0]
      if (file) {
        store.dispatch('uploadUserAvatar', { uid: this.userInfo.uid, file }).then(() => {
          this.$bvToast.toast(this.$t('your profile image was updated successfully.'), {
            title: this.$t('success'),
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
        }).catch(error => {
          this.$bvToast.toast(error, {
            title: this.$t('failed'),
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
        })
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.$set(this, 'imgFile', reader.result)
        }
      }
    },
    actionHandle() {
      if (!this.editable) {
        this.$set(this, 'editable', true)
      } else {
        this.$set(this, 'editable', false)
        if (this.userName && this.userName !== this.userInfo.displayName) {
          store.dispatch('updateUserName', { name: this.userName })
        }
      }
    },
    actionCancelHandle() {
      this.defaultValue()
      this.$set(this, 'editable', false)
    },
    changeValue(key, value) {
      let check = null
      if (key === 'userName' && (value !== '' && value !== this.userInfo.displayName)) {
        check = true
      }

      this.$set(this, key, value)
      this.$set(this, 'validations', {
        ...this.validations,
        [key]: check,
      })
    },
  },
}
</script>
