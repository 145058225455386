var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"settings-general",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Old password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Old password'),"label-for":"oldPassword"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"oldPassword","type":_vm.oldPasswordFieldType,"placeholder":_vm.$t('Old password'),"disabled":!_vm.editable,"state":errors.length > 0 ? false : null,"required":""},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.oldPasswordToggleIcon},on:{"click":_vm.toggleOldPasswordVisibility}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('New password'),"rules":"required","vid":"newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('New password'),"label-for":"newPassword"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{ref:"newPassword",attrs:{"id":"newPassword","type":_vm.passwordFieldType,"placeholder":_vm.$t('New password'),"disabled":!_vm.editable,"state":errors.length > 0 ? false : null,"required":""},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.newPasswordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Enter the password again'),"rules":"required|confirmed:newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Enter the password again'),"label-for":"confirmPassword"}},[_c('b-form-input',{attrs:{"id":"confirmPassword","type":"password","placeholder":_vm.$t('Enter the password again'),"disabled":!_vm.editable,"state":errors.length > 0 ? false : null},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","disabled":invalid && _vm.editable},on:{"click":_vm.actionHandle}},[_vm._v(" "+_vm._s(_vm.editable ? _vm.$t('Save') : _vm.$t('Edit'))+" ")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.actionCancelHandle}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }